<template>
  <CForm @submit.prevent="create" method="POST">
    <CCard no-header>
      <CCardBody>
        <h3 v-text="method"></h3>
        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
        <CRow>
          <CCol col="10"></CCol>
          <CCol class="text-right">
            <div><label>Active</label></div>
            <CSwitch color="success" size="lg" :checked.sync="partner.active"></CSwitch>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CInput label="Name" type="text" v-model="partner.name"></CInput>
          </CCol>
          <CCol sm="6">
            <CSelect label="Default Language" :value.sync="partner.languageId" :plain="true" :options="languages">
            </CSelect>
          </CCol>
          <CCol sm="6">
            <CSelect label="Time Zone" :value.sync="partner.timeZone" :plain="true" :options="timeZones">
            </CSelect>
          </CCol>
          <CCol sm="6">
            <CInput label="Stripe Account ID" type="text" v-model="partner.stripeConnectAccountId"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="VAT Number" type="text" v-model="partner.vatNumber"></CInput>
          </CCol>
          <CCol sm="6">
            <CSelect label="Country" :value.sync="partner.country" :plain="true" :options="countries">
            </CSelect>
          </CCol>
          <CCol sm="6">
            <CInput label="Partner Info Email" type="text" v-model="partner.partnerEmail"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="Email Name" type="text" v-model="partner.emailName"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="Own Sale Commission Rate" type="text" v-model="partner.ownSaleCommissionRate"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="Partner Sale Commission Rate" type="text" v-model="partner.partnerSaleCommissionRate"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="Sale Commission VAT Rate" type="text" v-model="partner.saleCommissionVATRate"></CInput>
          </CCol>
          <CCol col="4">
            <CInputCheckbox label="Are Prices Tax Included" :checked.sync="partner.arePricesTaxIncluded" />
          </CCol>
          <CCol sm="6">
            <div><label>Logo</label></div>
            <input id="file" ref="file" type="file" @change="handleFileUpload" />
          </CCol>

          <CCol col="12" v-if="partner.logo">
            <br />
            <br />
            <div>
              <img :src="partner.logo" class="img-thumbnail" style="max-height: 200px" />
            </div>
          </CCol>
        </CRow>
        <h3 style="margin-top: 20px !important">
          <CIcon name="cilMoney" /> Payment Methods
        </h3>

        <CRow>
          <CCol style="margin-left: 1vw !important" col="3" v-for="(paymentMethod, index) in allPossiblePaymentMethods">
            <CRow>
              <CInputCheckbox :id="'method-' + paymentMethod"> </CInputCheckbox>
              <label :for="'method-' + paymentMethod"><b>{{
                paymentMethod == 1
                ? "Stripe Card"
                : paymentMethod == 2 ? "Stripe Klarna"
                  : paymentMethod == 3 ? "Pay Later"
                    : paymentMethod == 4 ? "Stripe Ideal"
                      : paymentMethod == 5 ? "Stripe Link"
                        : "Pay Later"
              }}</b></label>
            </CRow>

            <CRow>
              <CCol style="margin-left: 0.5vw !important" col="3" v-for="(currency, index) in currencies">
                <CRow>
                  <h6>{{ currency.name }}</h6>
                </CRow>
                <CRow>
                  <label :for="'fixedComission-' + paymentMethod + currency.currencyCode
                    ">
                    Fixed Comission</label>
                  <CInput type="text" v-model="allowedPaymentMethodsDummy[currency.currencyCode + paymentMethod.toString() + 'fixed']" :id="'fixedComission-' + paymentMethod + currency.currencyCode
                    " />
                </CRow>
                <CRow>
                  <label :for="'percentageCommision-' +
                    paymentMethod +
                    currency.currencyCode
                    ">Percentage Comission</label>
                  <CInput v-model="allowedPaymentMethodsDummy[currency.currencyCode + paymentMethod.toString() + 'percentage']" type="text" :id="'percentageCommision-' +
                    paymentMethod +
                    currency.currencyCode
                    " />
                </CRow>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <h3 style="margin-top: 20px !important">
          <CIcon name="cilSettings" /> Integrations
          <hr />
        </h3>
        <CRow style="padding-top: 10px">
          <CCol sm="3">
            <CSelect label="Select an integration to enable" :plain="true" :value.sync="addedIntegration"
              :options="integrations">
            </CSelect>
          </CCol>
          <CCol col="3">
            <CButton color="success" class="btn" @click="pushIntegration()" style="margin-top: 3.5vh !important">
              <CIcon name="cilSmilePlus"></CIcon>
              Enable
            </CButton>
          </CCol>
        </CRow>
        <h3>List of enabled integrations:</h3>
        <li v-for="item in activatedIntegrations">
          {{ integrations.find((x) => x.value == item.id)?.label }}
          <CButton style="margin-left: 10px" @click="eliminateEnabledIntegration(item.id)">
            <CIcon name="cilXCircle" />
          </CButton>
        </li>
        <CRow>
          <CCol col="9"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="secondary" @click="goBack" style="margin-right: 15px">
              <CIcon name="cil-list" /> Back to the list
            </CButton>
            <CButton color="primary" type="submit">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
export default {
  name: "CreateOrUpdatePartner",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      method: "Create New Partner",
      timeZones: [],
      languages: [],
      allPossiblePaymentMethods: [],
      currencies: [],
      countries: [],
      integrations: [],
      activatedIntegrations: [],
      addedIntegration: null,
      partner: {
        id: "00000000-0000-0000-0000-000000000000",
        active: true,
        name: null,
        timeZone: null,
        file: null,
        languageId: null,
        allowedPaymentMethods: [],
        partnerEmail: "",
        emailName: "",
        arePricesTaxIncluded: false,
        stripeConnectAccountId: null,
        country: null,
        ownSaleCommissionRate: 0,
        partnerSaleCommissionRate: 0,
        saleCommissionVATRate: 0,
        vatNumber: "",
      },
      allowedPaymentMethodsDummy: {},
      toBePushed: {
        paymentMethodType: null,
        currencyCode: null,
        currencyId: null,
        fixedComission: null,
        percentageComission: null,
      },
      // Alert
      alertType: "success",
      message: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    pushIntegration() {
      if (this.addedIntegration != null && this.addedIntegration != "") {
        this.activatedIntegrations.push({ id: this.addedIntegration });
        let self = this;
        if (self.partner.id != "00000000-0000-0000-0000-000000000000") {
          let form = new FormData();
          form.append("partnerId", self.partner.id);
          form.append("integrationId", this.addedIntegration);
          axios
            .post(this.$apiAdress + "/v1/Partner/enableIntegrations", form)
            .then((response) => {
                        self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
              self.message = "Successfully enabled integration.";
            })
            .catch(function (error) {
                        self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
              self.message = error;
            });
        }

        this.addedIntegration = null;
      }
    },
    eliminateEnabledIntegration(id) {
      this.activatedIntegrations = this.activatedIntegrations.filter(
        (x) => x.id != id
      );
      let self = this;

      if (self.partner.id != "00000000-0000-0000-0000-000000000000") {
        let form = new FormData();
        form.append("partnerId", self.partner.id);
        form.append("integrationId", id);
        axios
          .post(this.$apiAdress + "/v1/Partner/disableIntegrations", form)
          .then((response) => {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully disabled integration.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      }
    },
    handleFileUpload(event) {
      this.partner.file = event.target.files[0];
    },
    getTimeZones() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/TimeZones")
        .then(function (response) {
          self.timeZones = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getCurrencies() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Currency")
        .then(function (response) {
          response.data.map(function (value, key) {
            value.name = value.name;
            value.currencyId = value.currencyId;
            value.currencyCode = value.currencyCode;
            value.checked = false;
            self.currencies.push(value);
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getPossiblePaymentMethods() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/PaymentMethods/list")
        .then(function (response) {
          self.allPossiblePaymentMethods = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    getLanguages() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/Languages/")
        .then(function (response) {
          self.languages = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getCountryList() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/CountryList")
        .then(function (response) {
          self.countries = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getListOfIntegrations() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/Integrations/")
        .then(function (response) {
          self.integrations = [];
          response.data.map((item) => {
            self.integrations.push(item);
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      let self = this;
      let form = new FormData();
      form.append("id", self.partner.id);
      form.append("name", self.partner.name);
      form.append("timeZone", self.partner.timeZone);
      form.append("file", self.partner.file);
      form.append("active", self.partner.active);
      form.append("languageId", self.partner.languageId);
      form.append(
        "stripeConnectAccountId",
        self.partner.stripeConnectAccountId
      );
      form.append(
        "country",
        self.partner.country == null ? "" : self.partner.country
      );
      form.append(
        "VATNumber",
        self.partner.vatNumber == null ? "" : self.partner.vatNumber
      );
      form.append("ownSaleCommissionRate", self.partner.ownSaleCommissionRate);
      form.append(
        "partnerSaleCommissionRate",
        self.partner.partnerSaleCommissionRate
      );
      form.append(
        "partnerEmail",
        self.partner.partnerEmail == null ? "" : self.partner.partnerEmail
      );
      form.append(
        "emailName",
        self.partner.emailName == null ? "" : self.partner.emailName
      );
      form.append("arePricesTaxIncluded", self.partner.arePricesTaxIncluded);
      form.append("saleCommissionVATRate", self.partner.saleCommissionVATRate);
      self.partner.allowedPaymentMethods = [];
      self.allPossiblePaymentMethods.forEach((item, index) => {
        if (document.getElementById("method-" + item).checked == true) {
          self.currencies.forEach((currency, index2) => {
            var value = {};
            value.currencyCode = currency.currencyCode;
            value.currencyId = currency.id;
            value.fixedComission =
              document.getElementById(
                "fixedComission-" + item + currency.currencyCode
              ).value == ""
                ? 0
                : document.getElementById(
                  "fixedComission-" + item + currency.currencyCode
                ).value;
            value.percentageComission =
              document.getElementById(
                "percentageCommision-" + item + currency.currencyCode
              ).value == ""
                ? 0
                : document.getElementById(
                  "percentageCommision-" + item + currency.currencyCode
                ).value;
            value.paymentMethodType = item;
            self.partner.allowedPaymentMethods.push(value);
          });
        }
      });
      form.append(
        "allowedPaymentMethodsJson",
        JSON.stringify(self.partner.allowedPaymentMethods)
      );
      axios
        .post(this.$apiAdress + "/v1/Partner", form)
        .then((response) => {
          self.partner.id = response.data;
          self.partner.allowedPaymentMethods = [];
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated partner.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    getPartner(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Partner/" + id)
        .then(async function (response) {
          self.partner = response.data;
          await self.sleep(2000);
          response.data.allowedPaymentMethods.forEach((item, index) => {
            self.allowedPaymentMethodsDummy[item.currencyCode + item.paymentMethodType.toString() + "fixed"] = item.fixedComission;
            self.allowedPaymentMethodsDummy[item.currencyCode + item.paymentMethodType.toString() + "percentage"] = item.percentageComission;
            document.getElementById(
              "fixedComission-" + item.paymentMethodType + item.currencyCode
            ).value = item.fixedComission;
            document.getElementById(
              "percentageCommision-" +
              item.paymentMethodType +
              item.currencyCode
            ).value = item.percentageComission;
            document.getElementById(
              "method-" + item.paymentMethodType
            ).checked = true;
          });
          self.partner.vatNumber = response.data.vatNumber;
          self.partner.pmsExternalURL = response.data.pmsExternalURL;
        })
        .catch(function (error) {
          self.message = error;
        });
    },

    getAllEnabledIntegration(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Partner/getAllEnabled/" + id)
        .then(async function (response) {
          response.data.map(item => {
            self.activatedIntegrations.push({ id: item.integrationId });

          })
        })
        .catch(function (error) {
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    self.getTimeZones();
    self.getCurrencies();
    self.getPossiblePaymentMethods();
    self.getLanguages();
    self.getCountryList();
    self.getListOfIntegrations();
    // Edit OR Create
    if (self.$route.params.id != this.emptyGuid) {
      self.method = "Edit Partner";
      self.getPartner(self.$route.params.id);
      self.getAllEnabledIntegration(self.$route.params.id);
    }
  },
};
</script>
